let ltd;
function fetchData(params = {}, cb) {
    $('#spinner-modal-trigger').trigger('click');
    $.ajax({
        type: 'GET',
        url: window.location.pathname,
        dataType: 'json',
        data: params,
        error: function (xhr, status, error) {
            const e = 'AJAX Error: ' + status + error;
            console.error(e);
            $('#spinner-modal button').trigger('click');

        },
        success: function (response) {
            $('#spinner-modal button').trigger('click');
            if (cb) cb(response);
        },
    });
}

function populateDropdownOptions(el, options, selectedValue, productDropdown = false) {
    el.empty()
        .html("<option value=''>Please select</option>");
    if (!options || options.length < 1) return;
    if (options.length < 2) selectedValue = options[0].id
    options.forEach(function (option) {
        const optionValue = option.id;
        const optionText = option.name
            ? option.name
            : option.first_name && option.last_name
                ? `${option.first_name} ${option.last_name}`
                : option.first_name
                    ? option.first_name
                    : option.surname
                        ? option.surname
                        : "";
        const selected = selectedValue == optionValue ? "selected" : "";
        if (productDropdown == true) {
            $("#old_product_id").val(selectedValue)
        }
        el.append(
            '<option value="' +
            optionValue +
            '" ' +
            selected +
            ">" +
            optionText +
            "</option>"
        );
    });
}

function createUrlParams(paramsObject) {
    const params = new URLSearchParams();

    for (const key in paramsObject) {
        if (paramsObject.hasOwnProperty(key)) {
            params.append(key, paramsObject[key]);
        }
    }

    return params.toString();
}

$(function () {
    const transfer_to = $('#transfer_to');
    const bene_currency = $('#bene_currency');
    const delivery_mode = $('#delivery_mode');
    const search_product = $('#search_product');
    const agent = $('#agent');
    const retrieve_link = $('#retrieve-link');
    const dropdowns = [transfer_to, bene_currency, delivery_mode, search_product];

    function updateLink() {

        if (transfer_to.val()) retrieve_link.removeClass('pointer-events-none opacity-75');
        else retrieve_link.addClass('pointer-events-none opacity-75');
        $('#tt-list-body input.transaction-checkbox').prop('checked', false).trigger("change");
        $("#tt-list-body").empty()
        $('#tt-placeholder').removeClass('hidden')
        setTimeout(function () {
            const url = retrieve_link.attr('href').split('?')[0];
            retrieve_link.attr('href', url + '?' + createUrlParams({
                transfer_to: transfer_to.val(),
                bene_currency: bene_currency.val(),
                delivery_mode: delivery_mode.val(),
                search_product: search_product.val(),
                agent: agent.val()
            }));
        }, 300);
    }

    function updateFilters(newData) {
        addRemoveInvalid()
        if (!newData) return;
        if (newData.bene_currencies) {
            populateDropdownOptions(
                bene_currency,
                newData.bene_currencies.map(function (bc) {
                    return { id: bc.id, name: bc.label_with_code };
                }),
                bene_currency.val())
        }
        if (newData.products) {
            populateDropdownOptions(search_product, newData.products, search_product.val(), true);
        }
        if (newData.new_products) {
            const new_product_el = $('#new_product_id');
            populateDropdownOptions(new_product_el, newData.new_products, search_product.val());
            if (!newData.paying_agents && newData.new_products.length == 1) {
                setTimeout(() => {
                    calculateAjax(newData.new_products[0].id);
                }, 200);
            }
        }
        if (newData.agents) populateDropdownOptions(agent, newData.agents, agent.val())
        if (!newData.correspondent_network && newData.paying_agents) {
            $(".paying_agent").show();
            $(".new_product_dropdown").hide();
            const new_paying_agent_el = $('#new_paying_agent_id');
            populateDropdownOptions(new_paying_agent_el, newData.paying_agents);
            if (newData.paying_agents.length == 1) {
                setTimeout(() => {
                    calculateAjaxForAgent(newData.paying_agents[0].id);
                }, 200);
            }
        } else {
            $(".paying_agent").hide();
            $(".new_product_dropdown").show();
        }
    }

    function updateDeliveryMode(corrNetwork) {
        var $deliveryMode = $('#delivery_mode');
        var $cashTransferOption = $('#delivery_mode option[value="1"]');
        var $bankTransferOption = $('#delivery_mode option[value="2"]');

        if (corrNetwork === true) {
            $cashTransferOption.remove();


            $deliveryMode.val('');
            if ($bankTransferOption.length > 0) {
                $deliveryMode.val('2');
            }


        } else {

            if ($cashTransferOption.length === 0) {
                $deliveryMode.append('<option value="1">Cash transfer</option>');
            }

            $deliveryMode.val('');
        }
    }


    transfer_to.on('change', function () {
        resetRate();
        fetchData({
            transfer_to: this.value
        }, function (response) {
            // console.log(response);
            updateFilters(response);
            updateDeliveryMode(response.correspondent_network)
            updateLink();
        });
        // var selectedText = $(this).find("option:selected").text();
        // if (selectedText === "Pakistan" || selectedText === "Bangladesh") {
        //     $("#delivery_mode option[value='1']").hide();
        // } else {
        //     $("#delivery_mode option[value='1']").show();
        // }
    });

    bene_currency.on('change', function () {
        resetRate();
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: this.value
        }, function (response) {
            // console.log(response);
            updateFilters(response);
            updateLink();
        });
    });

    delivery_mode.on('change', function () {
        resetRate();
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: bene_currency.val(),
            delivery_mode: this.value
        }, function (response) {
            // console.log(response);
            updateFilters(response);
            updateLink();
        });
    });

    search_product.on('change', function () {
        resetRate();
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: bene_currency.val(),
            delivery_mode: delivery_mode.val(),
            search_product: this.value
        }, function (response) {
            // console.log(response);
            updateFilters(response);
            updateLink();
        });
    });

    agent.on('change', function () {
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: bene_currency.val(),
            delivery_mode: delivery_mode.val(),
            search_product: search_product.val(),
            agent: this.value
        }, function (response) {
            // console.log(response);
            updateFilters(response);
            updateLink();
        });
    });

    $('#new_product_id').on('change', function () {
        const newProduct = this.value;
        if (!newProduct) {
            $('#cr').val('');
            return;
        }
        const loaded_transactions = $('#tt-list-body').children().length;
        if (loaded_transactions < 1) return; //checks if transactions were listed or not
        calculateAjax(newProduct)
        // console.log($('#new_product_id').val())
    });

    $('#new_paying_agent_id').on('change', function () {
        const newAgent = this.value;
        if (!newAgent) {
            $('#cr').val('');
            return;
        }
        const loaded_transactions = $('#tt-list-body').children().length;
        if (loaded_transactions < 1) return; //checks if transactions were listed or not
        calculateAjaxForAgent(newAgent);
    });

    function calculateAjaxForAgent(newAgent) {
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: bene_currency.val(),
            delivery_mode: delivery_mode.val(),
            agent: agent.val(),
            new_product_id: search_product.val(),
            new_paying_agent_id: newAgent,
            get_rates: true
        }, function (response) {
            // console.log(response);
            $('#cr').empty().val(response.currency_rate)
            ltd = response.ltd;

            $(".transaction-checkbox").trigger('change');
        });
    }

    function calculateAjax(newProduct) {
        fetchData({
            transfer_to: transfer_to.val(),
            bene_currency: bene_currency.val(),
            delivery_mode: delivery_mode.val(),
            agent: agent.val(),
            new_product_id: newProduct,
            get_rates: true
        }, function (response) {
            // console.log(response);
            $('#cr').empty().val(response.currency_rate)
            ltd = response.ltd;

            $(".transaction-checkbox").trigger('change');
        });
    }

    if ($('#new_product_id').val()) {
        let changedProductId = $("#new_product_id").val()
        calculateAjax(changedProductId)
    }
    const initialAgent = $('#new_paying_agent_id').val();
    if (initialAgent) {
        calculateAjaxForAgent(initialAgent)
    }


    function addRemoveInvalid() {
        dropdowns.forEach(selector => {
            if (selector.val()) {
                selector.removeClass('is-invalid');
            }
        });
    }


    retrieve_link.on("click", function () {
        dropdowns.forEach(selector => {
            if (!selector.val()) {
                selector.addClass('is-invalid');
            }
        });
    });
});

function resetRate() {
    ltd = 0;
    $('#cr').val('');
    calculateSum();
}

function calculateSum() {
    let sum = 0;
    // let total = 0;
    let total = 0;
    const totalChecked = $(".transaction-checkbox:checked")
    totalChecked.each(function () {
        const transferAmount = parseFloat($(this).closest("tr").find(".lc").text());
        const fcAmount = parseFloat($(this).closest("tr").find(".fc").text());
        sum += transferAmount;
        total += fcAmount;
        // total = sum * ltd;
    });
    // Update the sum wherever you want to display it
    $("#total-amount").empty().val(total ? total.toFixed(2) : '');
    $('#lc-total-amount').empty().val(sum ? sum.toFixed(2) : '');
    $(".total-trx").text(totalChecked.length)
}

$(document).ready(function () {
    $(document).on("change", ".transaction-checkbox", function () {
        calculateSum();
        updateSelectAllCheckbox();
    });
    calculateSum();
    $('#tt-select-all').on('change', function () {
        const selectAll = this.checked;
        $('#tt-list-body input:checkbox').prop('checked', selectAll).trigger('change');
    });

    function updateSelectAllCheckbox() {
        const totalCheckboxes = $('#tt-list-body input:checkbox').length;
        const checkedCheckboxes = $('#tt-list-body input:checkbox:checked').length;

        if (totalCheckboxes === checkedCheckboxes && totalCheckboxes > 0) {
            $('#tt-select-all').prop('checked', true);
        } else {
            $('#tt-select-all').prop('checked', false);
        }
    }

    updateSelectAllCheckbox();

});




